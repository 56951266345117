import React, { useEffect } from "react";
import { Router, Route } from "react-router-dom";
import { Redirect, Switch } from "react-router";
import { connect, useDispatch } from "react-redux";
import { Toaster } from "react-hot-toast";
import { history } from "./managers/history";
import { Home, Story } from "./modules";
import Policy from "./modules/privacyPolicy";
import TermsServices from "./modules/termsAndService";
import Login from "./modules/signIn";
import CreatedStories from "./modules/createdStories";
import ReadedStories from "./modules/readedStories";
import SignUp from "./modules/signUp";
import Profile from "./modules/profile";
import About from "./modules/about";
import SubscriptionPlan from "./modules/subscribePlan";
import ForgotPassword from "./modules/forgotPassword";
import Billing from "./modules/billing";
import { SubscriptionService } from "./services";
import { actionTypeConstants } from "./constants";
import Feedback from "./modules/feedback";
import BrowseMore from "./modules/viewMoreStories";
import CancellationPolicy from "./modules/cancelSubscription";
import ChangePassword from "./modules/changePassword";
import SetPassword from "./modules/newPassword";
import StoryBook from "./modules/storyBook";
import BookmarkStories from "./modules/bookmarkStories";
import TaleCreationLoader from "./modules/story/taleCreationLoader";
import Utils from "./utility";

const { SET_USER_PLAN, SET_LOADING_SUB } = actionTypeConstants;

const Routes = ({ user, story }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (!user.isLoggedIn) return;
      try {
        dispatch({ type: SET_LOADING_SUB, payload: true });
        const response = await new SubscriptionService().getSubscribedPlans({
          userId: user.userId,
        });
        if (response?.status === "NO_VALID_PLAN") {
          dispatch({
            type: SET_USER_PLAN,
            payload: { hasPlan: false, expired: false, planDetails: {} },
          });
        } else if (response?.status === "EXPIRED") {
          dispatch({
            type: SET_USER_PLAN,
            payload: { hasPlan: false, expired: true, planDetails: {} },
          });
        } else if (response?.status === "ACTIVE") {
          dispatch({
            type: SET_USER_PLAN,
            payload: { hasPlan: true, expired: false, planDetails: response },
          });
        }
      } catch (error) {
        console.log("error: ", error);
      } finally {
        dispatch({ type: SET_LOADING_SUB, payload: false });
      }
    })();
    // eslint-disable-next-line 
  }, []);

  const updateStory = () => {
    dispatch({ type: "UPDATE_STORY_STATUS", payload: { storyStatus: { showLoader: false, isReady: false }, showLayer: false } });
    Utils.navigateToStory(story.generatedStory?._id, story.generatedStory?.storyTitle);
    dispatch({ type: "UPDATE_GENERATED_STORY", payload: {} });

  };

  return (
    <div className="bg-black-10 min-h-screen">
      {story.storyStatus.showLoader && story.showLayer && (
          <div className="creating-your-story-container fixed bottom-12.5 z-50 flex justify-center md:block md:w-fit md:right-12.5">
              <TaleCreationLoader
                  isReady={story.storyStatus.isReady}
                  onClick={updateStory}
              />
          </div>
      )}
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/story/:storyTitle" component={Story} />
          <Route path="/privacy-policy" component={Policy} />
          <Route path="/terms-service" component={TermsServices} />
          <Route path="/created-stories" component={CreatedStories} />
          <Route path="/read-stories" component={ReadedStories} />
          <Route path="/login" component={Login} />
          <Route path="/signup" component={SignUp} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/set-password" component={SetPassword} />
          <Route path="/change-password" component={ChangePassword} />
          <Route path="/profile/:userId" component={Profile} />
          <Route path="/about" component={About} />
          <Route path="/subscription-plan" component={SubscriptionPlan} />
          {/* <Route path="/billing" component={Billing} /> */}
          <Route path="/my-bookmark" component={BookmarkStories} />
          <Route path="/feedback" component={Feedback} />
          <Route path="/cancellation" component={CancellationPolicy} />
          <Route exact path="/browse/:type" component={BrowseMore} />
          <Route path="/story-book" component={StoryBook} />
          <Redirect exact from="*" to="/" />
        </Switch>
        <Toaster />
      </Router>
    </div>
  );
};

const mapStateToProps = (state) => ({ user: state.user, story: state.story });

export default connect(mapStateToProps)(Routes);
