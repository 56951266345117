import React, { useState, useEffect } from "react";
import { useStripe, useElements, AddressElement, CardNumberElement, CardCvcElement, CardExpiryElement } from "@stripe/react-stripe-js";
import { BiLoaderAlt } from "react-icons/bi";
import Utils from "../../../utility";
import { coutriesDontSupportRecurring } from "../../../constants";
import { SubscriptionService } from "../../../services";
import { history } from "../../../managers/history";

const PaymentCard = ({ plan, handleSubscribePlan }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [address, setAddress] = useState({});
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [cardFocus, setCardFocus] = useState({
    number: false,
    cvc: false,
    expiry: false,
  });

  // useEffect(() => {
  //   if (!plan?._id) history.push("/subscription-plan");
  //   // eslint-disable-next-line
  // }, []);

  const style = {
    base: {
      color: "white",
      fontSize: "17px",
      fontFamily: "Poppins",
      fontWeight: 400,
    },
    empty: {
      color: "#6C6C6C",
    },
  };

  const handleError = (message) => {
    setError(message);
    setIsLoading(false);
  };


  const createPayment = async (paymentMethod, user) => {
    try {
      setIsLoading(true);
      const paymentParams = {
        planId: plan?._id,
        paymentMethodId: paymentMethod?.id,
        user: user,
        currencyType: user.currency,
      };
      const subsResponse = await new SubscriptionService().createPayment(paymentParams);
      const { error: paymentError, paymentIntent } = await stripe?.confirmCardPayment(subsResponse?.clientSecret);

      if (paymentError) {
        handleError(paymentError?.message || "An error occurred making a payment, Please try again");
        return;
      }

      const subscription = {
        planId: plan?._id,
        planType: plan?.planType,
        paymentType: "ONE_TIME",
        paymentId: subsResponse?.paymentId,
        price: paymentIntent.amount / 100 || plan?.price,
        billingDetails: { paymentIntent },
      };
      await handleSubscribePlan(subscription);
    } catch (err) {
      console.log("err: ", err);
      setIsLoading(false);
      Utils.failureToast("An error occurred making a payment, Please try again");
    } finally {
      setIsLoading(false);
    }
  };

  const createSubscription = async (paymentMethod, user) => {
    try {
      setIsLoading(true);
      const subsResponse = await new SubscriptionService().createSubscription({
        planId: plan?._id,
        paymentMethodId: paymentMethod?.id,
        user,
      });
      const { error: paymentError, paymentIntent } = await stripe?.confirmCardPayment(subsResponse?.clientSecret);

      if (paymentError) {
        handleError(paymentError?.message || "An error occurred creating a subscription, Please try again");
        return;
      }

      const subscription = {
        planId: plan?._id,
        planType: plan?.planType,
        paymentType: "RECURRING",
        subscriptionId: subsResponse?.subscriptionId,
        paymentId: subsResponse?.subscriptionId,
        price: paymentIntent?.amount / 100 || plan?.price,
        subscriptionDetails: { paymentIntent },
        billingDetails: { paymentIntent },
      };

      await handleSubscribePlan(subscription);
    } catch (err) {
      console.log("err: ", err);
      setIsLoading(false);
      Utils.failureToast("An error occurred creating a subscription, Please try again");
    }
  };

  const handlePay = async () => {
    try {
      if (error) setError("");
      if (!address.complete || !email.trim()) {
        setError("Please fill all the above fields");
        return;
      }
      if (!Utils.validateEmail(email)) {
        setError("Please enter a valid email");
        return;
      }

      const cardElement = elements.getElement(CardNumberElement);
      const { error: paymentError, paymentMethod } = await stripe?.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          name: address?.value?.name,
          email: email,
          address: address?.value?.address,
        },
      });

      if (paymentError) {
        handleError(paymentError?.message);
        return;
      }

      const currency = address?.value?.address?.country === "IN" ? "inr" : "usd";
      const user = { name: address?.value?.name, email: email, currency };

      if (coutriesDontSupportRecurring.includes(address?.value?.address?.country)) {
        createPayment(paymentMethod, user);
      } else {
        createSubscription(paymentMethod, user);
      }
    } catch (err) {
      setIsLoading(false);
      console.log("err: ", err);
    }
  };

  const isFormValid = address.complete && !!email.trim();

  return (
    <div className="max-w-527px mx-auto md:px-0 px-4">
      <div className="flex justify-between font-PoppinsMedium md:text-ft20-37 text-ft18-27 items-center text-white ">
        <p>Total Payment</p>
        <p>
          {Utils.formatCurrency(Number(plan?.price), "USD")}/
          <span className="capitalize">{plan?.duration}</span>
        </p>
      </div>
      <div className="bg-gray-70 rounded-3">
        <div className="px-5.5 py-7.5">
          <div className="text-gray-10 inline-block mb-1 w-full">
            <input
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              id="Field-emailInput"
              type="email"
              placeholder="Email"
              className="remove-prefill-autofill change-prefill-text-color text-ft17-26 placeholder-gray-10 h-13.5 bg-gray-70 rounded-7.5 px-4 focus:outline-none border border-gray-10 focus:border-white block w-full text-white font-PoppinsRegular"
            />
          </div>
          <div className="mt-5 mb-2 text-white">
            <label className="text-gray-10 text-ft17-26 font-PoppinsMedium">
              Card Infomation
            </label>
            <div
              className={`h-12.5 pt-3.5 bg-gray-70 mt-2 rounded-t-3xl px-4 focus:outline-none w-full text-gray-10 font-PoppinsRegular border ${
                cardFocus.number ? "border-white" : "border-gray-10"
              }`}
            >
              <CardNumberElement
                onFocus={() => setCardFocus((p) => ({ ...p, number: true }))}
                onBlur={() => setCardFocus((p) => ({ ...p, number: false }))}
                options={{ showIcon: true, style }}
              />
            </div>
            <div
              className={`h-12.5 bg-gray-70 flex  w-full text-gray-10 font-PoppinsRegular`}
            >
              <div
                className={`w-1/2 pt-3.5 px-4 rounded-bl-3xl ${
                  cardFocus.expiry
                    ? "border border-white"
                    : "border-b border-l border-r border-gray-10"
                }`}
              >
                <CardExpiryElement
                  onFocus={() => setCardFocus((p) => ({ ...p, expiry: true }))}
                  onBlur={() => setCardFocus((p) => ({ ...p, expiry: false }))}
                  options={{ style }}
                />
              </div>
              <div
                className={`w-1/2 pt-3.5 px-4 rounded-br-3xl ${
                  cardFocus.cvc
                    ? "border border-white"
                    : "border-b border-r border-gray-10"
                }`}
              >
                <CardCvcElement
                  onFocus={() => setCardFocus((p) => ({ ...p, cvc: true }))}
                  onBlur={() => setCardFocus((p) => ({ ...p, cvc: false }))}
                  options={{ style }}
                />
              </div>
            </div>
          </div>

          <AddressElement
            options={{ mode: "billing" }}
            onChange={setAddress}
            className="pt-5"
          />
          <button
            onClick={handlePay}
            disabled={isLoading || !isFormValid}
            className={` text-white w-full md:h-75px h-14 grid place-content-center rounded-lg font-PoppinsMedium text-ft20-37 mt-10 bg-red-10 ${
              !isFormValid && "opacity-50"
            }`}
          >
            {isLoading ? (
              <BiLoaderAlt className="w-8 h-8 animate-spin" />
            ) : (
              `Pay ${Utils.formatCurrency(Number(plan?.price), "USD")}`
            )}
          </button>
          {!!error && (
            <div className="text-red-20 my-1 text-center text-ft14-21">
              {error}
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-center pt-8">
        <img src="/images/powered-by-stripe.svg" alt="" />
      </div>
    </div>
  );
};

export default PaymentCard;
