import React, { useEffect, useReducer, useState } from "react";
import { profanity } from "@2toad/profanity";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../common/components/footer";
import Header from "../../common/components/header";
import StoriesList from "./storiesList";
import reducer, { initialState } from "./reducer";
import StoryService from "../../services/storyService";
import { sessionManager } from "../../managers/sessionManager";
import {
  exportHomeConstants,
  errorMessagesConstants,
  toastMessageConstants,
  actionTypeConstants,
  characterNames,
  storyArray,
  randomStoryArray,
} from "../../constants";
import LoadingScreen from "../../common/components/loadingScreen";
import MainSection from "./mainSection";
import Utils from "../../utility";
import { history } from "../../managers/history";
import { Auth0Service, UserService, SubscriptionService } from "../../services";
import CreateSubscription from "../../services/subscriptionService";
import profaneWords from "../../constants/profane-words";
profanity.addWords(profaneWords);

const { ACTION_TYPES } = exportHomeConstants;
const { UDPATE_STATE } = ACTION_TYPES;
const { UPDATE_USER, SET_USER_PLAN } = actionTypeConstants;

const randomStoriesListOne =
  storyArray[Math.floor(Math.random() * storyArray.length)];
const randomStoriesListTwo =
  randomStoryArray[Math.floor(Math.random() * randomStoryArray.length)];

const Home = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [language, setLanguage] = useState(false);
  const [currLang, setCurrLang] = useState("");
  const [configs, setConfigs] = useState({
    age: [],
    characters: [],
    mainCharacters: [],
    type: [],
  });

  useEffect(() => {
    const googleTrans = sessionManager.getDataFromCookies("googtrans");
    let currentLanguage = googleTrans ? googleTrans.split("/")[2] : "en";
    setCurrLang(currentLanguage);
  }, [language]);

  const user = useSelector((userState) => userState.user);
  const [totalStory] = useState([]);

  const reduxDispatch = useDispatch();

  const updateState = (obj) => {
    if (obj.character && obj.character === state.mainCharacter) {
      dispatch({
        type: UDPATE_STATE,
        payload: {
          character: `${obj.character} 1`,
          mainCharacter: `${obj.character} 2`,
        },
      });
    } else if (obj.mainCharacter && obj.mainCharacter === state.character) {
      dispatch({
        type: UDPATE_STATE,
        payload: {
          character: `${obj.mainCharacter} 1`,
          mainCharacter: `${obj.mainCharacter} 2`,
        },
      });
    } else {
      dispatch({ type: UDPATE_STATE, payload: obj });
    }
  };

  const getPlans = async () => {
    try {
      return await new CreateSubscription().getPlans();
    } catch {
      return {};
    }
  };
  const SubscriptionPlan = async (userId, planId, planType) => {
    try {
      return await new CreateSubscription().subscribePlan({
        userId,
        planId,
        planType,
      });
    } catch {
      return {};
    }
  };

  const getUserDetails = async (userId) => {
    try {
      return await new UserService().getUserData({ userId });
    } catch {
      return {};
    }
  };

  const getUserPlan = async (userId) => {
    try {
      return await new SubscriptionService().getSubscribedPlans({
        userId,
      });
    } catch {
      return {};
    }
  };

  function checkPlan({ storyConfig, subscriptionPlan, userDetails }) {
    if (subscriptionPlan?.status === "NO_VALID_PLAN") {
      handleNoValidPlan();
      return;
    }

    if (subscriptionPlan?.status === "EXPIRED") {
      handleExpiredPlan();
      return;
    }

    const isUnlimited = isUnlimitedPlan(subscriptionPlan);

    if (isUnlimited) {
      createStory(storyConfig, userDetails);
      handlePlanUpdate(subscriptionPlan);
      return;
    }

    if (isLimitExceeded(subscriptionPlan)) {
      handleLimitExceeded();
      return;
    }

    createStory(storyConfig, userDetails);
    handlePlanUpdate(subscriptionPlan);
  }

  function handleNoValidPlan() {
    // history.push("/subscription-plan");
    reduxDispatch(getPlanUpdateAction(false, false, {}));
  }

  function handleExpiredPlan() {
    reduxDispatch(getPlanUpdateAction(false, true, {}));
    Utils.failureToast("Your plan has expired.");
    // setTimeout(() => {
    //   history.push("/subscription-plan");
    // }, 1500);
  }

  function isUnlimitedPlan(subscriptionPlan) {
    return (
      String(subscriptionPlan?.featureBalances?.AIStory)?.toLowerCase() ===
      "unlimited"
    );
  }

  function isLimitExceeded(subscriptionPlan) {
    return (
      Number(subscriptionPlan?.featureBalances?.AIStory) < 1 &&
      Number(subscriptionPlan?.dailyCredits.AIStory) < 1
    );
  }

  function handleLimitExceeded() {
    Utils.failureToast(errorMessagesConstants.LIMIT_EXCEEDED);
    reduxDispatch(getPlanUpdateAction(false, false, {}));
    setTimeout(() => {
      history.push("/subscription-plan");
    }, 1500);
  }

  function handlePlanUpdate(subscriptionPlan) {
    const planDeductedBalance =
      Number(subscriptionPlan?.dailyCredits?.AIStory) < 1
        ? {
            ...subscriptionPlan,
            featureBalances: {
              ...subscriptionPlan?.featureBalances,
              AIStory:
                Number(subscriptionPlan?.featureBalances?.AIStory || 0) - 1,
              audioBook:
                Number(subscriptionPlan?.featureBalances?.audioBook || 0) - 1,
            },
          }
        : {
            ...subscriptionPlan,
            dailyCredits: {
              ...subscriptionPlan?.dailyCredits,
              AIStory: Number(subscriptionPlan?.dailyCredits?.AIStory || 0) - 1,
              audioBook:
                Number(subscriptionPlan?.dailyCredits?.audioBook || 0) - 1,
            },
          };

    reduxDispatch(getPlanUpdateAction(true, false, planDeductedBalance));
  }

  function getPlanUpdateAction(hasPlan, expired, planDetails) {
    return {
      type: SET_USER_PLAN,
      payload: {
        hasPlan,
        expired,
        isLoading: false,
        planDetails,
      },
    };
  }

  function redirectToLink(planType) {
    const redirectLink = sessionManager.getDataFromCookies("REDIRECT_LINK");

    if (redirectLink) {
      sessionManager.removeDataFromCookies("REDIRECT_LINK");

      if (redirectLink === "/story-book/home" && planType === "paid") {
        history.push("/story-book/create-book");
      } else {
        history.push(redirectLink);
      }
    } else {
      history.push("/");
    }
  }

  useEffect(() => {
    (async () => {
      if (window.location.hash?.startsWith("#access_token")) {
        updateState({ isLoading: true });
        const params = new URLSearchParams(document.location.hash);
        const accessToken = params.get("#access_token");
        try {
          const response = await new Auth0Service().userInfo();
          const userData = {
            name: response?.name,
            email: response?.email,
            userId: response?.sub,
            firstName: response?.given_name,
            lastName: response?.family_name,
            profilePicture: response?.picture,
            accessToken,
          };
          let [userDetails, userPlan] = await Promise.all([
            getUserDetails(response.sub),
            getUserPlan(response.sub),
          ]);
          await new UserService().saveUser(userData);
          reduxDispatch({
            type: UPDATE_USER,
            payload: {
              ...userData,
              profilePicture: !!userDetails?.profilePicture
                ? userDetails?.profilePicture
                : response?.picture,
              userIntro: userDetails?.userIntro || "",
              _id: userDetails?._id,
              role: userDetails?.role,
            },
          });
          if (userPlan?.status === "NO_VALID_PLAN" || userPlan?.status === "EXPIRED") {
            const plans = await getPlans();
            const planIndex = plans.findIndex((plan) => plan.planType === "Free");
            userPlan = await SubscriptionPlan(
              response.sub,
              plans[planIndex]._id,
              plans[planIndex].planType
            );
          }

          const storyConfig = sessionManager.getDataFromCookies("story_config");
          if (!storyConfig) {
            if (userPlan?.status === "EXPIRED") {
              reduxDispatch({
                type: SET_USER_PLAN,
                payload: {
                  hasPlan: true,
                  expired: true,
                  isLoading: false,
                  planDetails: {},
                },
              });
            } else {
              reduxDispatch({
                type: SET_USER_PLAN,
                payload: {
                  hasPlan: true,
                  expired: false,
                  isLoading: false,
                  planDetails: userPlan,
                },
              });
            }
            redirectToLink(userPlan?.planType?.toLowerCase());
            return;
          }
          checkPlan({ storyConfig, subscriptionPlan: userPlan, userDetails });
        } catch (error) {
          console.log("error: ", error);
          Utils.failureToast(toastMessageConstants.SIGNUP_ERROR);
          setTimeout(() => {
            history.push("/");
          }, 1500);
        } finally {
          updateState({ isLoading: false });
        }
      }
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await new StoryService().getConfig();
        setConfigs({
          ...response,
          characters: !!response?.characters
            ? ["Boy", "Girl", ...response?.characters]
            : ["Boy", "Girl"],
          mainCharacters: !!response?.characters
            ? ["Boy", "Girl", ...response?.characters]
            : ["Boy", "Girl"],
        });
      } catch (error) {
        console.log("error: ", error);
      }
    })();
  }, []);

  const createStory = async (requestData, userDetails) => {
    updateState({ isLoading: true });
    try {
      if (state.isNameChanged) requestData.name = state.name;
      Utils.storeToLocalStorage("selected_config", requestData);
      const query = new URLSearchParams({
        age: requestData.age,
        character: requestData.character,
        type: requestData.type,
      }).toString();
      const [[story], createRes] = await Promise.all([
        new StoryService().getStory(query),
        new StoryService().createStory({
          ...requestData,
          userId: userDetails?.userId || user.userId,
          characterNamedAs: requestData.name || state.name,
        }),
      ]);
      if (!story || !story?.storyParagraphs || !createRes?.amqResponse)
        throw new Error("");
      const paragraphs = Utils.organizeParagraph(
        story.storyParagraphs,
        story.images
      );
      reduxDispatch({
        type: "UPDATE_STORY",
        payload: {
          paragraphs,
          ...story,
          storyConfig: {
            ...requestData,
            fetchData: true,
            storyId: createRes?.storyId,
          },
        },
      });

      Utils.navigateToStory(story?._id, story?.storyTitle);
    } catch (error) {
      Utils.failureToast(error?.message);
      console.log("error: ", error);
    } finally {
      updateState({ isLoading: false });
    }
  };

  async function checkPlanStatus(storyConfig) {
    const { subscriptionPlan } = user;
    const { planDetails = {} } = subscriptionPlan;
    if (!subscriptionPlan.hasPlan) {
      const plans = await getPlans();
      const planIndex = plans.findIndex((plan) => plan.planType === "Free");
      if (user.userId && plans[planIndex].planType === "Free") {
        let subscriptionResponse = await SubscriptionPlan(
          user.userId,
          plans[planIndex]._id,
          plans[planIndex].planType
        );
        const planDeductedBalance =
          Number(subscriptionResponse?.dailyCredits?.AIStory) < 1
            ? {
              ...subscriptionResponse,
              featureBalances: {
                ...subscriptionResponse?.featureBalances,
                AIStory:
                  Number(
                    subscriptionResponse?.featureBalances?.AIStory || 0
                  ) - 1,
                audioBook:
                  Number(
                    subscriptionResponse?.featureBalances?.audioBook || 0
                  ) - 1,
              },
            }
            : {
              ...subscriptionResponse,
              dailyCredits: {
                ...subscriptionResponse?.dailyCredits,
                AIStory:
                  Number(subscriptionResponse?.dailyCredits?.AIStory || 0) -
                  1,
                audioBook:
                  Number(subscriptionResponse?.dailyCredits?.audioBook || 0) -
                  1,
              },
            };
        reduxDispatch({
          type: SET_USER_PLAN,
          payload: {
            hasPlan: true,
            expired: false,
            planDetails: planDeductedBalance,
          },
        });
        createStory(storyConfig);
        sessionManager.setDataInCookies("story_config", {
          ...storyConfig,
          name: state.name,
        });
        return;
      } else {
        history.push("/");
      }
      return;
    }
    const isUnlimited =
      String(planDetails?.featureBalances?.AIStory)?.toLowerCase() ===
      "unlimited";
    if (isUnlimited) {
      createStory(storyConfig);
      return;
    }
    if (subscriptionPlan.expired) {
      Utils.failureToast(
        "Your plan has been expired"
      );
      sessionManager.setDataInCookies("story_config", {
        ...storyConfig,
        name: state.name,
      });
      // setTimeout(() => {
      //   history.push("/subscription-plan");
      // }, 1500);
      return;
    }

    const balance =
      Number(planDetails?.featureBalances?.AIStory) +
      Number(planDetails?.dailyCredits?.AIStory);

    if (balance < 1) {
      Utils.failureToast(errorMessagesConstants.LIMIT_EXCEEDED);
      sessionManager.setDataInCookies("story_config", {
        ...storyConfig,
        name: state.name,
      });
      // setTimeout(() => {
      //   history.push("/subscription-plan");
      // }, 1500);
      return;
    }
    reduxDispatch({ type: "DEDUCT_BALANCE" });
    createStory(storyConfig);
  }

  const onGenerate = async (request) => {

    if (profanity.exists(request.name)) {
      Utils.failureToast(errorMessagesConstants.BAD_WORD_WARNING);
      updateState({ name: "" });
      return;
    }

    if (profanity.exists(request.mainCharacterName)) {
      Utils.failureToast(errorMessagesConstants.BAD_WORD_WARNING);
      updateState({ mainCharacterName: "" });
      return;
    }
    const requestData = {
      age: request.age,
      character: request.character,
      type: request.type,
      mainCharacterNamedAs: request.mainCharacterName,
      mainCharacter: request.mainCharacter,
    };
    if (!user.isLoggedIn) {
      sessionManager.setDataInCookies("story_config", requestData, 30);
      history.push("/login");
      return;
    }
    checkPlanStatus(requestData);
  };

  useEffect(() => {
    const selectedConfig = Utils.getDataFromLocalStorage("selected_config");
    const mainCharacterName = Utils.pickRandom(characterNames.male);
    const type = Utils.pickRandom(configs?.type);
    const character = Utils.pickRandom(configs?.characters);
    const age = Utils.pickRandom(configs?.age);
    const females = ["Girl", "Alice", "Cinderella"];
    let name = "";

    if (females.includes(character))
      name = Utils.pickRandom(characterNames.female);
    else {
      const filteredNames = [
        ...characterNames.male,
        ...characterNames.female,
      ].filter((val) => val !== mainCharacterName);
      name = Utils.pickRandom(filteredNames);
    }

    updateState({
      age: selectedConfig?.age || age || "4",
      character: character || selectedConfig?.character || "Tiger",
      mainCharacterName: mainCharacterName || selectedConfig?.mainCharacterName,
      type: type || selectedConfig?.type || "Adventure",
      name: name || selectedConfig?.name || "Brian",
    });
    // eslint-disable-next-line
  }, [configs]);

  const Stories = [
    {
      title: `${totalStory} Latest Stories`,
      type: "latest",
    },
    {
      title: "Popular Stories",
      type: "popular",
    },
    {
      title: `${randomStoriesListOne} Stories`,
      type: randomStoriesListOne,
    },
    {
      title: `${randomStoriesListTwo} Stories`,
      type: randomStoriesListTwo,
    },
  ];

  return (
    <div className="min-h-screen bg-black-10">
      <Header setLanguage={setLanguage} />
      {state.isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <MainSection
            updateState={updateState}
            state={state}
            configs={configs}
            currLang={currLang}
            onGenerate={onGenerate}
          />

          {Stories.map((list, idx) => (
            <>
              <StoriesList key={idx} {...list} list={list} user={user} />
            </>
          ))}
        </>
      )}
      <Footer />
    </div>
  );
};

export default Home;
