import React from "react";
import { Link } from "react-router-dom";
import { BsFacebook , BsInstagram } from "react-icons/bs";
import { AiFillTwitterSquare } from "react-icons/ai";

const Footer = () => {
  const pathname = window.location.pathname
  function openSocialMediaLink(logo) {
    let url;
    switch (logo) {
      case "facebook":
        url = "https://www.facebook.com/MakeMyTaleOfficial";
        break;
      case "twitter":
        url = "https://twitter.com/makemytale";
        break;
      case "instagram":
        url = "https://www.instagram.com/makemytale_official/";
        break;
      default:
        console.error(``);
        return;
    }
    window.open(url, "_blank");
  }

  return (
    <footer className={`${pathname.includes("/story-book/book")? "hidden" : ""} border-t border-black-20 bg-black-10 py-8`}>
      <div className="max-w-310 mx-auto flex justify-center flex-col px-5.5 min-h-55">
        <div className="flex md:justify-between md:items-center md:flex-row flex-col">
          <div className="text-white flex md:gap-7 gap-7.75 md:items-center md:flex-row flex-col md:mt-0 mt-6.5">
            <div className="md:text-ft36-50 text-ft25-37 font-LobsterRegular text-gray-10">
              MakeMyTale
            </div>
            <div className="flex ">
              <BsInstagram
                onClick={() => openSocialMediaLink("instagram")}
                className="mr-1 w-6 cursor-pointer"
              />
              <BsFacebook
                onClick={() => openSocialMediaLink("facebook")}
                className="mr-1 w-6 cursor-pointer"
              />
              <AiFillTwitterSquare
                onClick={() => openSocialMediaLink("twitter")}
                className="cursor-pointer w-7"
              />
            </div>
            <div className="text-ft14-21 md:hidden 2lg:flex font-PoppinsRegular flex md:gap-1 2lg:gap-10  gap-2 md:flex-col 2lg:flex-row flex-col">
              <Link className="hidden 2md:block" to="/about">
                About Us
              </Link>
              <Link to="/privacy-policy" className="min-w-22">Privacy Policy</Link>
              <Link to="/terms-service" className="min-w-22">Terms of Service</Link>
              {/* <Link to="/cancellation" className="min-w-22">Cancellation Policy</Link> */}
              <Link to="/feedback" className="min-w-22">Feedback</Link>
            </div>
          </div>
          <div className="text-gray-10 hover:underline text-ft14-21 font-PoppinsRegular md:mt-0 mt-4.5">
            <a href="mailto:support@makemytale.com">support@makemytale.com </a>
          </div>
        </div>
        <div className="text-ft14-21 hidden md:flex 2lg:hidden mt-8 font-PoppinsRegular text-white md:gap-10 2lg:gap-10  gap-2  flex-row ">
          <Link to="/about">About Us</Link>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/terms-service">Terms of Service</Link>
          {/* <Link to="/cancellation">Cancellation Policy</Link> */}
          <Link to="/feedback">Feedback</Link>
        </div>

        <div className="mt-8">
          <div className="text-ft20-37 text-white font-PoppinsMedium">
            Disclaimer :
          </div>
          <div className="text-ft14-21 text-white font-PoppinsRegular">
            The AI-generated kids books on this website are intended for
            entertainment and creative inspiration only. They are not meant to
            replace traditional books, which play a crucial role in child
            development and education. The content generated by the AI may
            contain inaccuracies or inconsistencies and should not be relied
            upon as a substitute for professional advice or educational
            material. Use of this website is at your own risk and we assume no
            responsibility for any loss or harm resulting from the use of the
            generated content.
          </div>
          <div className="text-ft14-21 text-white font-PoppinsRegular mt-2">
            Our AI system may occasionally generate copyrighted images. We do
            not intend to infringe on any copyrights, so please notify us if you
            believe that any of our content infringes on your copyright. We will
            promptly remove any infringing content upon notification.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
