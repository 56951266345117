import React, { useEffect, useState } from "react";
import Footer from "../../common/components/footer";
import Header from "../../common/components/header";
import StoriesList from "./storiesList";
import LoadingScreen from "../../common/components/loadingScreen";
import Pagination from "../../common/components/pagination";
import { UserService } from "../../services";
import { useSelector } from "react-redux";
import { history } from "../../managers/history";

function CreatedStories({ location }) {
  const [loading, setIsLoading] = useState(true);
  const [stories, setStories] = useState({
    data: [],
    total: 0,
  });

  const user = useSelector((state) => state.user);

  const queryParams = new URLSearchParams(location.search);
  const page = Number(queryParams.get("page")) || 1;
  const limit = 12;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onPageChange = (page) => {
    history.push({
      search: new URLSearchParams({
        page,
      }).toString(),
    });
  };

  useEffect(() => {
    (async () => {
      if (!user.isLoggedIn) {
        history.push("/");
        return;
      }
      try {
        setIsLoading(true);
        const { data = [], total = 0 } =
          await new UserService().getReadedStories({
            userId: user.userId,
            limit,
            skip: (page - 1) * limit,
          });
        setStories({ data, total });
      } catch (error) {
        console.log("error: ", error);
      } finally {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line
  }, [page, location.pathname]);

  return (
    <div className="min-h-screen bg-black-10">
      <Header showCreateNew />
      {loading ? (
        <LoadingScreen />
      ) : (
        <StoriesList
          title="My Read Stories"
          storiesList={stories.data}
          user={user}
        />
      )}
      <Pagination
        onPageChange={onPageChange}
        limit={limit}
        total={stories.total}
        page={page}
      />
      <Footer />
    </div>
  );
}

export default CreatedStories;
