import React, { useRef, useEffect } from "react";
import DropDown from "./dropDown";
import { history } from "../../managers/history";
import { useSelector} from "react-redux";
import { maxLengthconstants } from "../../constants";
import Utils from "../../utility";

const MainSection = ({ state, updateState, configs, onGenerate,currLang }) => {

  const errors = useRef({ name: false });
  const user = useSelector((userState) => userState.user);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onNameChange = (name) => {
    if (!/\s/.test(name)) {
      const { NAME } = maxLengthconstants;
      if (name.length > NAME) {
        if (!errors.current.name) {
          errors.current.name = true;
          setTimeout(() => {
            errors.current.name = false;
          }, 1500);
          Utils.failureToast(
            "Please shorten the character name to 20 characters or less.",
            {
              duration: 1500,
            }
          );
        }
        return;
      }
      updateState({ name });
      updateState({ isNameChanged: true });
    }
  };

  const onSubmit = () => {
    history.push("/subscription-plan");
  };
  const {
    subscriptionPlan: { planDetails },
  } = user;

  const onSecondNameChange = (name) => {
    if (!/\s/.test(name)) {
      const { NAME } = maxLengthconstants;
      if (name.length > NAME) {
        if (!errors.current.name) {
          errors.current.name = true;
          setTimeout(() => {
            errors.current.name = false;
          }, 1500);
          Utils.failureToast(
            "Please shorten the character name to 20 characters or less.",
            {
              duration: 1500,
            }
          );
        }
        return;
      }
      updateState({ mainCharacterName: name });
      updateState({ isNameChanged: true });
    }
  };
  return (
    <section className="max-w-310  mx-auto px-4 md:px-6 lg:px-4 md:mt-10 mt-4 text-white font-TimesBold">
      <div className="typingEffect">
        Create your own AI-powered story.
      </div>
      <div className="max-w-310 md:min-h-77.5 border-2 rounded-lg bg-black-0 border-dashed border-black-20">
        <div className="py-12 pl-3 pr-3 md:pl-10 md:pr-11.5 flex flex-wrap font-PoppinsRegular md:text-ft36-50 text-ft26-50 text-white">

          {currLang === "en" && ( <span translate="no" className="md:inline-block block">Create a story for</span>)}

          {currLang === "es" && (<span translate="no" className="md:inline-block block">Crea una historia para</span>)}

          {currLang === "hi" && ( <span translate="no" className="md:inline-block block">एक कहानी बनाएँ</span>)}

          {currLang === "fr" && ( <span translate="no" className="md:inline-block block">Créez une histoire pour un enfant de</span> )}

          {currLang === "ru" && ( <span translate="no" className="md:inline-block block">Создайте историю для</span> )}

          {currLang === "it" && ( <span translate="no" className="md:inline-block block">Crea una storia per</span> )}

          {currLang === "pt" && ( <span translate="no" className="md:inline-block block">Crie uma história para uma</span>)}

          <DropDown
            value={state.age}
            onChange={(age) => updateState({ age })}
            options={configs?.age || []}
            suffix="Years old"
            fieldWidth="min-w-79"
          />
          {""}
          {currLang === "en" && ( <span translate="no">about a</span>)}

          {currLang === "es" && ( <span translate="no">sobre un</span> )}

          {currLang === "hi" && ( <span>के बच्चे के लिए एक</span>)}

          {currLang === "fr" && ( <span translate="no">sur un</span> )}

          {currLang === "ru" && ( <span translate="no">о</span> )}

          {currLang === "it" && ( <span translate="no">di un</span> )}

          {currLang === "pt" && ( <span translate="no">sobre uma</span>)}

          <DropDown
            value={state.character}
            onChange={(character) => updateState({ character })}
            options={configs?.characters || []}
            fieldWidth="min-w-265px"
          />
          {currLang === "en" && ( <span translate="no">and a</span> )}

          {currLang === "es" && ( <span translate="no">y un</span> )}

          {currLang === "hi" && (  <span>और एक</span>  )}

          {currLang === "ru" && ( <span>и</span>  )}

          {currLang === "fr" && ( <span translate="no">et un</span>)}

          {currLang === "it" && (<span translate="no">e un</span> )}

          {currLang === "pt" && ( <span translate="no">e um</span> )}

          <DropDown
            value={state.mainCharacter}
            onChange={(mainCharacter) => updateState({ mainCharacter })}
            options={configs?.mainCharacters || []}
            fieldWidth="min-w-265px"
          />
          <span className="md:block hidden text-ft36-20 pt-5 mr-3">.</span>
          {currLang === "en" && ( <span translate="no">It should be</span> )}

          {currLang === "es" && ( <span translate="no">Debería ser</span> )}

          {currLang === "hi" && ( <span>के बारे में। यह</span> )}

          {currLang === "ru" && ( <span>Это должна быть</span> )}

          {currLang === "fr" && (<span translate="no">Ça devrait être</span>)}

          {currLang === "it" && (<span translate="no">Dovrebbe essere</span> )}

          {currLang === "pt" && (<span translate="no">Deve ser</span>)}

          <DropDown
            value={state.type}
            onChange={(type) => updateState({ type })}
            options={configs?.type || []}
            fieldWidth="min-w-54.5"
          />
          <span className="md:block hidden text-ft36-20 pt-5">.</span>{" "}
           &nbsp;
          {currLang === "en" && (<span translate="no">Call</span>)}

          {currLang === "es" && ( <span translate="no">Call</span>)}

          {currLang === "hi" && ( <span>होना चाहिए।</span>)}

          {currLang === "ru" && ( <span>Назовите</span> )}

          {currLang === "fr" && (<span translate="no">Appelez la</span>)}

          {currLang === "it" && (<span translate="no">Chiama</span>)}

          {currLang === "pt" && (<span translate="no">Nomeie</span>)}

          {" "}
          <div className="flex md:inline-block lowercase pl-2 ">
          {state.character}{" "}
          {currLang === "en" && ( <span translate="no">as</span> )}

          {currLang === "es" && ( <span translate="no">como</span>)}

          {currLang === "hi" && ( <span>को</span>)}

          {currLang === "ru" && ( <span>как</span> )}

          {currLang === "fr" && ( <span translate="no">comme</span> )}

          {currLang === "it" && ( <span translate="no">come</span> )}

          {currLang === "pt" && (<span translate="no">como</span> )}
          {" "}
          </div>
          <input
            placeholder=""
            value={state.name}
            onChange={(e) => onNameChange(e.target.value)}
            className="md:w-265px w-full md:pl-12 md:pr-6 bg-transparent outline-none font-PoppinsSemiBold text-red-20 border-b border-black-20 text-ft22-33 md:text-ft41-50"
          />{" "}
          <div className="flex md:inline-block lowercase">
          {currLang === "en" && (<span translate="no">and</span>)}

          {currLang === "es" && (<span translate="no">y</span>)}

          {currLang === "hi" && (<span>और</span>)}

          {currLang === "ru" && (<span>и</span>)}

          {currLang === "fr" && (<span translate="no">et le</span>)}

          {currLang === "it" && (<span translate="no">e</span>)}

          {currLang === "pt" && (<span translate="no">e</span>)}

           {" "}{state.mainCharacter}{" "}
          {currLang === "en" && (<span translate="no">as</span>)}

          {currLang === "es" && (<span translate="no">como</span>)}

          {currLang === "hi" && (<span>को</span>)}

          {currLang === "ru" && (<span>как</span>)}

          {currLang === "fr" && (<span translate="no">comme</span>)}

          {currLang === "it" && (<span translate="no">come</span>)}

          {currLang === "pt" && (<span translate="no">como</span>)}
          {" "}
          </div>
          <input
            placeholder=""
            value={state.mainCharacterName}
            onChange={(e) => onSecondNameChange(e.target.value)}
            className="md:w-265px w-full md:pl-12 md:pr-6 bg-transparent outline-none font-PoppinsSemiBold text-red-20 border-b border-black-20 text-ft22-33 md:text-ft41-50"
          />{" "}
          {currLang === "hi" && (
            <span translate="no">बुलाओ।</span>
          )}
          <span className="md:block hidden text-ft36-20 pt-5">. &nbsp;</span>
        </div>
        <div className=" items-center justify-center flex mb-6">
          <button
            onClick={() =>
              onGenerate({
                age: state.age,
                character: state.character,
                type: state.type,
                name: state.name,
                mainCharacterName: state.mainCharacterName,
                mainCharacter: state.mainCharacter,
              })
            }
            className="md:w-68 w-8/12 rounded-11 bg-red-20 hover:bg-red-30 text-white h-12.5 font-PoppinsMedium md:text-ft20-37 text-ft20-37"
          >
            Generate my story
          </button>
        </div>
        {planDetails.planType === "Free" ? (
          <div className=" items-center text-center justify-center content-center pb-4 pt-0 text-ft15-21  font-PoppinsRegular text-base text-gray-110">
            {" "}
            You have{" "}
            {planDetails?.featureBalances?.AIStory || 0}{" "}
            credit left.
          </div>
        ) : null}
      </div>
    </section>
  );
};

export default MainSection;
