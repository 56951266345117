/**
 * Created by Ayush Kulshrestha on 18/09/2019.
 */

export const httpConstants = {
  METHOD_TYPE: {
    POST: "POST",
    PUT: "PUT",
    GET: "GET",
    DELETE: "DELETE",
    PATCH: "PATCH"
  },
  CONTENT_TYPE: {
    APPLICATION_JSON: "application/json",
    MULTIPART_FORM_DATA: "multipart/form-data",
    APPLICATION_FORM_URLENCODED: "application/x-www-form-urlencoded",
    IMAGE_PNG: "image/png",
  },
  DEVICE_TYPE: {
    WEB: "web",
  },
  API_END_POINT: {
    GET_A_STORY: "/get-a-story",
    CREATE_A_STORY: "/create-a-story",
    GET_CONFIG: "/get-config",
    REPORT_A_STORY: "/report-a-story",
    RATE_A_STORY: "/rate-a-story",
    GET_STORIES: "/get-stories",
    GET_STORY_STATUS: "/get-story-status",
    REGENERATE_IMAGE: "/regenerate-image",
    READ_STORY: "/read-story",
    ADD_READ_STORY: "/read-story",
    SAVE_USER: "/save-user",
    USER_CREATED_STORY: "/user-created-story",
    STORY_VIEW_COUNT: "/story-view-count",
    GET_PLANS: "/get-plans",
    RAZORPAY_ORDER: "/razorpay-order",
    CREATE_SUBSCRITPION: "/create-subscription",
    CANCEL_SUBSCRITPION: "/cancel-subscription",
    CREATE_PAYMENT: "/create-payment",
    SUBSCRIPTION_PLAN: "/subscription-plan",
    GET_SUBSCRIBED_PLAN: "/get-subscribed-plan",
    GET_USER: "/get-user",
    UPLOAD_PICTURE: "/upload-picture",
    STORY_BY_TITLE: "/story-by-title",
    USER_RATING: "/user-rating",
    UPDATE_PROFILE: "/update-profile",
    GET_PROFILE: "/get-profile",
    CHANGE_PASSWORD: "/change-password",
    DELETE_STORY: "/delete-story",
    MULTIPLE_STORIES: "/multiple-stories",
    GET_BOOKS: "/books",
    GET_BOOKS_ID: "/books/",
    GENERATE_BOOK: "/generate-book",
    ADD_FEEDBACK: "/feedback",
    BOOKMARK_STORIES: "/bookmark-story",
    BOOKMARK_STORIES_BYID: "/bookmark/:userId/:storyId",
    UPLOAD: "/upload",
    UPDATE_STORY_PRIVACY: "/story-privacy"
  },
};

export const storyArray = [
  "Action",
  "Adventure",
  "Biography",
  "Crime comedy",
  "Cyberpunk",
  "Documentary",
  "Drama",
  "Family",
  "Fantasy",
  "Fairy tale",
];

export const randomStoryArray = [
  "Funny",
  "Historical",
  "Horror",
  "Musical",
  "Mystery",
  "Sci-Fi",
  "Sport",
  "Thriller",
  "War",
  "Western",
];

export const apiConstant = {
  API_FAILURE: "Something went wrong!, Please try again",
};

export const errorMessagesConstants = {
  BAD_WORD_WARNING:
    "The word you have entered is considered inappropriate and cannot be used.",
  NAME_REQUIRED: "Username is required.",
  PASSWORD_REQUIRED: "Password is required.",
  PHONE_NUMBER_REQUIRED: "Phone Number is required.",
  PASSWORD_MUST_8_CHARACTERS: "Password must be at least 8 characters.",
  PASSWORD_ALPHA_NUMBER_ERROR:
    "Password must include letters, numbers and special characters.",
  INVALID_EMAIL: "Please enter a valid email.",
  NAME_IS_REQUIRED: "Please enter your name.",
  EMAIL_REQUIRED: "Please enter your email.",
  FEEDBACK_REQUIRED: "Please enter your feedback.",
  USERID_REQUIRED: "User ID is required.",
  CONFIRM_PASSWORD_REQUIRED: "Confirm password is required.",
  PASSWORD_MUST_SAME: "New password and Confirm password must be same.",
  ERROR_OCCURED_RAZORPAY:
    "An error occurred while setting up razorpay, Please try again.",
    LIMIT_EXCEEDED: "You have 0 credit left."
};

export const toastMessageConstants = {
  USER_CREATE_SUCCESS: "User created successfully",
  SIGNUP_ERROR: "Sign Up Error, Please try again.",
  FORGOTPASSWORD_ERROR:
    "An error occurred while sending the recovery mail, Please try again.",
};

export const exportHomeConstants = {
  ACTION_TYPES: {
    UDPATE_STATE: "UDPATE_STATE",
  },
};

export const privacyPolicyList = [
  {
    heading: "Information We Collect",
    subheading:
      "When you use our website or app, we may collect the following types of information:",
    content:
      "Personal information, such as your name and email address, when you create an account  Information about your use of our website or app, such as the pages you visit and the features you use Information that you provide to us when you use our website or app, such as comments you make or stories you create.",
  },
  {
    heading: "Use of Information",
    subheading: "We use the information we collect from you to:",
    content:
      "Provide and improve our website and app.Respond to your requests for support or technical assistance.Send you communications about our website or app, such as updates or new features.",
  },
  {
    heading: "Sharing of Information",
    content:
      "We do not share your personal information with third parties, except as required by law or with your consent.",
  },
  {
    heading: "Security",
    content:
      "We take reasonable measures to protect your personal information from loss, misuse, and unauthorized access, disclosure, alteration, or destruction.",
  },
  {
    heading: "Parental Control",
    content:
      "We provide parents with the ability to review and control the personal information that we collect from their children. Parents can request to review their child's personal information, request that we delete their child's personal information, and request that we stop collecting or using their child's personal information.",
  },
  {
    heading: "Changes to This Policy",
    content:
      "We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons.",
  },
  {
    heading: "Contact Us",
    content:
      "If you have any questions about this Privacy Policy or our practices, please contact us at ",
  },
];

export const TermsandconditionData = [
  {
    heading: "Accounts and Registration",
    content:
      "In order to use certain features of our website or app, you may be required to register for an account. When you register for an account, you will be asked to provide certain personal information, such as your name and email address. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer or device. You agree to accept responsibility for all activities that occur under your account or password.",
  },
  {
    heading: "Content",
    content:
      "Our website and app allow users to create, share, and view short stories. You are solely responsible for the content that you upload, post, or otherwise make available through our website or app. By uploading, posting, or otherwise making content available through our website or app, you represent and warrant that you have the right to do so and that the content does not infringe on the intellectual property rights of any third party.You agree not to upload, post, or otherwise make available any content that is illegal, harmful, threatening, abusive, harassing, tortuous, defamatory, vulgar, obscene, libelous, invasive of another's privacy, hateful, or racially, ethnically, or otherwise objectionable.We reserve the right to remove any content that we determine, in our sole discretion, to be in violation of these Terms of Service or otherwise harmful to our website or app",
  },
  {
    heading: "Content Upload and Display",
    content:
      "By using our website and generating content, you hereby agree that we have the right to upload and display the content eg. videos on any social media website eg. If you wish to revoke your consent, you can contact us, but immediate removal or elimination of all copies cannot be guaranteed. Continued use of our website and services confirms your acceptance of these terms.",
  },
  {
    heading: "Third-Party Websites and Content ",
    content:
      "Our website and app may contain links to third-party websites or content. We do not control or endorse these third-party websites or content and are not responsible for their availability, accuracy, or content.",
  },
  {
    heading: "Modification and Termination",
    content:
      "We reserve the right to modify or discontinue our website or app, or any portion thereof, at any time, with or without notice to you. We also reserve the right to terminate your access to our website or app, or any portion thereof, at any time, with or without notice to you.",
  },
  {
    heading: " Indemnification",
    content:
      "You agree to indemnify and hold us, and our affiliates, officers, agents, and employees, harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your use of our website or app, or your violation of these Terms of Service.",
  },
  {
    heading: "Applicable Laws",
    content:
      "These Terms of Service shall be governed by and construed in accordance with the laws of the jurisdiction in which our website or app is operated, without giving effect to any principles of conflicts of law.",
  },
  {
    heading: "Contact Us",
    content:
      "If you have any questions about these Terms of Service or our practices, please contact us at ",
  },
];

export const actionTypeConstants = {
  UPDATE_USER: "UPDATE_USER",
  REMOVE_USER: "REMOVE_USER",
  SET_USER_PLAN: "SET_USER_PLAN",
  DEDUCT_BALANCE: "DEDUCT_BALANCE",
  SET_LOADING_SUB: "SET_LOADING_SUB",
  RESET_SUBSCRIPTION: "RESET_SUBSCRIPTION",
};

export const keyTypeConstants = {
  USER: "user",
  ACCESS_TOKEN: "ACCESS_TOKEN",
  AUTH0_ID_TOKEN: "AUTH0_ID_TOKEN",
};

export const aboutPageData = [
  {
    content:
      "At MakeMyTale, we understand the importance of nurturing a child's imagination and creativity from a young age. That's why we've created a platform that allows kids to generate their own unique stories based on the characters they love. With just a few clicks, kids can select the theme and characters for their story and let our AI technology do the rest.",
  },
  {
    content:
      "And now, with the addition of audio and video creation options, kids can bring their stories to life in a whole new way. They can generate an audio version of their story and even create a video version with visual effects. This allows kids to truly immerse themselves in their own storytelling adventures.",
  },
  {
    content:
      "Not only that, but kids can also share their stories with friends and family by putting their name as a co-author on the generated story. This creates a sense of pride and accomplishment in their work and encourages them to continue exploring their imagination and creativity.",
  },
  {
    content:
      "At MakeMyTale, we believe in providing a safe and secure platform where kids can express themselves freely. Our website is designed with the safety and privacy of our users in mind, and we are committed to ensuring that every child's experience on our platform is a positive one.",
  },
  {
    content:
      "So what are you waiting for? Join us today and experience the magic of AI-powered story creation like never before. Let your child's imagination run wild and start their next storytelling adventure with MakeMyTale.",
  },
];
export const cancelSubscriptionData = [
  {
    heading: "cancel",
    content:
      "You can cancel your subscription at any time, and your subscription will remain active until the end of the current billing cycle. Please note that we do not offer refunds for any unused portion of your subscription.If you cancel your subscription, you will retain access to MakeMyTale until the end of your current billing cycle. After the end of the billing cycle, your account will be automatically downgraded to a free account, and you will no longer be charged.If you choose to re-subscribe in the future, you will be charged at the current subscription rate.If you have any questions about our cancellation policy, please contact our support team at ",
  },
];

export const coutriesDontSupportRecurring = ["IN"];

export const reportOptions = [
  "Incorrect images in the story",
  "Spam or misleading",
  "Hateful or abusive",
  "Other",
];

export const storyBookConstants = {
  ACTION_TYPES: {
    UDPATE_STATE: "UDPATE_STATE",
    SELECT_STORY: "SELECT_STORY",
  },
};

export const characterNames = {
  male: [
    "Brian",
    "Leo",
    "Max",
    "Noah",
    "Owen",
    "Ethan",
    "Lucas",
    "Mason",
    "Jackson",
    "Logan",
    "Wyatt",
    "Caleb",
    "Levi",
    "Benjamin",
    "Samuel",
    "Elijah",
    "Isaac",
    "Gabriel",
    "Daniel",
    "Caleb",
    "Josiah",
    "Simon",
    "Gabe",
    "Asher",
    "Luke",
    "Jonah",
  ],
  female: [
    "Ava",
    "Lily",
    "Mia",
    "Zoe",
    "Emma",
    "Amelia",
    "Olivia",
    "Ella",
    "Abigail",
    "Chloe",
    "Emily",
    "Avery",
    "Harper",
    "Sofia",
    "Grace",
    "Scarlett",
    "Madison",
    "Elizabeth",
    "Victoria",
    "Aurora",
    "Isabella",
    "Penelope",
    "Lucy",
    "Hazel",
    "Delilah",
  ],
};


export const browseStoriesAndUrl = [
  {
    to: "/browse/latest?category=type&page=1",
    name: "Latest Stories",
  },
  {
    to: "/browse/adventure?category=type&page=1",
    name: "Adventure Stories",
  },
  {
    to: "/browse/comedy?category=type&page=1",
    name: "Comedy Stories",
  },
  {
    to: "/browse/drama?category=type&page=1",
    name: "Drama Stories",
  },
  {
    to: "/browse/family?category=type&page=1",
    name: "Family Stories",
  },
  {
    to: "/browse/horror?category=type&page=1",
    name: "Horror Stories",
  },
  {
    to: "/browse/mystery?category=type&page=1",
    name: "Mystery Stories",
  },
];

export const previewBook = {
  imageUrl: "https://d1tlbbnn6s3eny.cloudfront.net/books/642d08e1a7abae09cdfa946c/bookImage.png",
  bookId: "642d08e1a7abae09cdfa946c",
  pdfUrl: "https://d1tlbbnn6s3eny.cloudfront.net/books/642d08e1a7abae09cdfa946c/bookPdf.pdf",
}

export const maxLengthconstants = {
  NAME: 20,
}
export const translations = {
  en: "Regenerate",
  es: "Regenerar",
  hi: "पुनर्जन्म",
  fr: "Régénérer",
  ru: "Регенерировать",
  it: "Rigenerare",
  pt: "Regenerar"
};

